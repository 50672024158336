import $ from 'jquery';
import 'jquery-ui';
import { AjaxPromise } from "./shared/ajax_utilities";
import { BaseAutocomplete } from "./autocomplete/base_autocomplete";
import {generateGuid, getFilterKey, getFilterValue} from "./shared/common";
import { Validator } from '@cfworker/json-schema';
import {MultiAutocomplete} from "./autocomplete/multi_autocomplete";

class AutocompleteCore {
    AUTOCOMPLETES = {};
    AUTOCOMPLETE_CACHE = {};
    REFETCH_INTERVAL = 1000 * 60;
    AUTOCOMPLETE_CONTAINERS = {};
    LOADING = false;

    constructor() {
        app.AUTOCOMPLETE_CORE = this;

        // This is a helper hook event to allow on the fly rebuilds of auto completes
        window.addEventListener('RebuildAutocompletes', (_e) => {
            this.#setupAutocompletes();
        });

        this.#setupAutocompletes();
        window.dispatchEvent(new Event('AutocompleteCoreSetup'));
    }

    #setupDOM(el, autocompleteData, aid) {
        let uid;
        if(!el.parent().data('uid') || el.parent().data('uid').length === 0) {
            uid = generateGuid(12);
            el.parent().data('uid', uid);
            el.data('uid', uid);
        } else {
            uid = el.parent().data('uid');
        }

        this.AUTOCOMPLETE_CONTAINERS[uid] = {
            container: autocompleteData.multi ? el.parent().parent() : el.parent(),
            input: el,
        };

        this.AUTOCOMPLETE_CONTAINERS[uid].input_hidden = this.AUTOCOMPLETE_CONTAINERS[uid].container.find(el.attr('data-hidden') ? `#${el.attr('data-hidden')}` : 'input[type="hidden"]')
        const prefix = autocompleteData.tbl ?? aid;
        const html = `
            <i class="btn-autocomplete-clear btn-autocomplete-clear-${prefix} icon fa fa-times"></i>
            <i class="autocomplete-loading autocomplete-loading-${prefix} icon fad fa-spin fa-spinner-third"></i>
            <i class="autocomplete-search autocomplete-search-${prefix} icon fa fa-search"></i>`;
        const containerClass = (el.data('multi') === 'true') ? 'autocomplete-container autocomplete-container-multi' : 'autocomplete-container';
        el.after(html);
        this.AUTOCOMPLETE_CONTAINERS[uid].container.addClass(containerClass);

        this.AUTOCOMPLETE_CONTAINERS[uid].clear = this.AUTOCOMPLETE_CONTAINERS[uid].container.find(`.btn-autocomplete-clear-${prefix}`);
        this.AUTOCOMPLETE_CONTAINERS[uid].search = this.AUTOCOMPLETE_CONTAINERS[uid].container.find(`.autocomplete-search-${prefix}`);
        this.AUTOCOMPLETE_CONTAINERS[uid].loading = this.AUTOCOMPLETE_CONTAINERS[uid].container.find(`.autocomplete-loading-${prefix}`);

        el.unbind('focus').unbind('blur').unbind('keyup').unbind('keydown');
        el.on('focus', async (e) => {
            if(!this.AUTOCOMPLETES[aid] || !this.AUTOCOMPLETES[aid].onFocus) {
                return;
            }

            await this.AUTOCOMPLETES[aid].onFocus(e, uid);
        });

        el.on('blur', async (e) => {
            if(!this.AUTOCOMPLETES[aid] || !this.AUTOCOMPLETES[aid].onBlur) {
                return;
            }

            await this.AUTOCOMPLETES[aid].onBlur(e, uid);
        });

        el.on('keyup', async (e) => {
            if(!this.AUTOCOMPLETES[aid] || !this.AUTOCOMPLETES[aid].onKeyup) {
                return;
            }

            await this.AUTOCOMPLETES[aid].onKeyup(e, uid);
        });

        el.on('keydown', async (e) => {
            if(!this.AUTOCOMPLETES[aid] || !this.AUTOCOMPLETES[aid].onKeyDown) {
                return;
            }

            this.AUTOCOMPLETES[aid].onKeyDown(e, uid);
        });

        this.AUTOCOMPLETE_CONTAINERS[uid].clear.unbind('click').on('click', (e) => {
            if(!this.AUTOCOMPLETES[aid] || !this.AUTOCOMPLETES[aid].clearSelection) {
                return;
            }

            this.AUTOCOMPLETES[aid].clearSelection(e, uid);
        });

        if(this.AUTOCOMPLETES[aid] && this.AUTOCOMPLETES[aid].setup) {
            this.AUTOCOMPLETES[aid].setup(uid);
        }

        return uid;
    }

    #setupAutocompletes() {
        $.each($('.generic-autocomplete'), async (k, el) => {
            el = $(el);
            const autocompleteData = el.data();
            if(!autocompleteData || !autocompleteData.aid) {
                console.warn(`Unable to setup auto complete for input missing aid.`, el);
                return;
            }

            const aid = autocompleteData.aid;
            let url;
            if(autocompleteData.url) {
                url = autocompleteData.url;
            } else if(autocompleteData.tbl) {
                url = `${app.CACHE.URL_AJAX}${autocompleteData.tbl}/get`;
            } else {
                console.warn(`Unable to get data for autocomplete ${aid}. No data-url or data-tbl attribute provided.`);
                return;
            }

            if(!this.AUTOCOMPLETES[aid]) {
                this.AUTOCOMPLETES[aid] = autocompleteData.multi ? new MultiAutocomplete(aid, autocompleteData.tbl ?? aid) : new BaseAutocomplete(aid, autocompleteData.tbl ?? aid);
            }

            const uid = this.#setupDOM(el, autocompleteData, aid);
            if(app.DASHBOARD_CORE || app.OPTIONS.list) {
                el.on('focus', async (e) => {
                    await this.setup(autocompleteData, el, url, aid, uid);
                });

                if(this.AUTOCOMPLETE_CONTAINERS[uid] && this.AUTOCOMPLETE_CONTAINERS[uid].input &&
                    this.AUTOCOMPLETE_CONTAINERS[uid].input.val().trim().length > 0
                ) {
                    this.showClear(uid);
                } else {
                    this.showSearch(uid);
                }
            } else {
                await this.setup(autocompleteData, el, url, aid, uid);
            }
        });
    }

    async setup(autocompleteData, el, url, ref, uid) {

        if(!this.AUTOCOMPLETE_CACHE[ref] || !this.AUTOCOMPLETE_CACHE[ref].data) {
            await this.#getAutocompleteData(url, ref, autocompleteData, uid);
        }

        if(!this.AUTOCOMPLETE_CACHE[ref]) {
            console.warn('Unable to fetch autocomplete data.');
            return;
        }

        const nextFetchTime = (this.AUTOCOMPLETE_CACHE[ref].lastFetched + this.REFETCH_INTERVAL);
        if(nextFetchTime <= Date.now()) {
            await this.#getAutocompleteData(url, ref, autocompleteData, uid);
        }

        if(typeof el.autocomplete('instance') !== "undefined") {
            el.autocomplete('destroy'); // Destroy the old instance if one exists
        }

        const minLength = (this.AUTOCOMPLETE_CACHE[ref].data.length <= 10 ) ? 0 : 1;
        let opts = {};

        if(this.AUTOCOMPLETES[ref] && this.AUTOCOMPLETES[ref].autocompleteOptions) {
            opts = await this.AUTOCOMPLETES[ref].autocompleteOptions(autocompleteData, uid);
        }

        this.#checkDataFromUri(ref, uid, this.AUTOCOMPLETE_CACHE[ref].data ?? []);

        el.autocomplete({
            ...opts,
            minLength: minLength,
            source: this.AUTOCOMPLETE_CACHE[ref].data,
        });

        if(minLength === 0) {
            el.on('focus', (e) => {
                if(!e.currentTarget.value) {
                    $(e.currentTarget).autocomplete('search', '');
                }
            });
        }
    }

    #checkDataFromUri(ref, uid, json)
    {
        const uri = [3,4,5,6];

        uri.forEach(index => {
            if( getFilterKey(index) === ref){

                const row = json.filter( r => {
                    if( r.value == getFilterValue(index) ) {
                        return r;
                    }
                });

                if( row[0] ) {
                    this.AUTOCOMPLETES[ref].CONTAINER.input.val(row[0].label);
                    this.AUTOCOMPLETES[ref].CONTAINER.input_hidden.val(row[0].value);
                    this.showClear(uid);
                }
            }
        });
    }

    async #getAutocompleteData(url, ref, autocompleteData, uid) {
        try {
            this.LOADING = true;
            this.showLoading(uid);
            let opts = {
                url: url,
                method: autocompleteData.ajaxMethod ?? 'POST',
                data: autocompleteData ?? {},
            };

            if(this.AUTOCOMPLETES[ref] && this.AUTOCOMPLETES[ref].getAjaxData) {
                opts.data = await this.AUTOCOMPLETES[ref].getAjaxData(autocompleteData);
            }

            const res = await AjaxPromise(opts);
            this.LOADING = false;
            if(this.AUTOCOMPLETE_CONTAINERS[uid] && this.AUTOCOMPLETE_CONTAINERS[uid].input) {
                if(this.AUTOCOMPLETE_CONTAINERS[uid].input.val()) {
                    this.showClear(uid);
                } else {
                    this.showSearch(uid);
                }
            } else {
                this.showSearch(uid);
            }

            if(res.status !== 'success') {
                console.warn(`Failed to fetch data for autocomplete ${ref}.\n${url}:`, res);
                return;
            }

            const schema = {
                type: 'array',
                items: {
                    type: 'object',
                    required: ['label', 'value'],
                    properties: {
                        label: 'string',
                        value: 'string',
                    }
                }
            };
            const validation = new Validator(schema);
            const { valid, errors } = validation.validate(res.data ?? []);
            if(!valid) {
                console.warn(`Invalid JSON response ignoring response.`, ...(errors.map((error) => `\n${error.error}`)));
                return;
            }

            this.AUTOCOMPLETE_CACHE[ref] = {
                lastFetched: Date.now(),
                data: res.data ?? [],
            };

        } catch(err) {
            console.warn(`Failed to fetch data for autocomplete ${ref}.\n${url}:`, err);
        }
    }

    showClear(uid) {
        if(!this.AUTOCOMPLETE_CONTAINERS[uid]) {
            return;
        }

        this.AUTOCOMPLETE_CONTAINERS[uid].search.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].loading.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].clear.show();
    }
    showLoading(uid) {
        if(!this.AUTOCOMPLETE_CONTAINERS[uid]) {
            return;
        }

        this.AUTOCOMPLETE_CONTAINERS[uid].search.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].loading.show();
        this.AUTOCOMPLETE_CONTAINERS[uid].clear.hide();
    }

    showSearch(uid) {
        if(!this.AUTOCOMPLETE_CONTAINERS[uid]) {
            return;
        }

        this.AUTOCOMPLETE_CONTAINERS[uid].search.show();
        this.AUTOCOMPLETE_CONTAINERS[uid].loading.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].clear.hide();
    }
}

$(() => {
    new AutocompleteCore();
});